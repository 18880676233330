import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TotalHorizontalBarChart from "../components/organisms/HorizontalBarChart/TotalHorizontalBarChart"

const conversionRateData = [
  {doctor: 'Dr. Green', total: 25, value: 11, percentage: 44},
  {doctor: 'Dr. Jones', total: 50, value: 18, percentage: 36},
  {doctor: 'Dr. Smith', total: 25, value: 21, percentage: 84},
];

const HorizontalBarChartPage = () => (

  <Layout>
    <SEO title="Horizontal Bar Chart" />
    <div><Link to="/">Go back to the homepage</Link></div>
    <TotalHorizontalBarChart
      totalWidth={1050}
      totalHeight={400}
      conversionRateData={conversionRateData}
      labelKey='doctor'
      color='#bbc3c1'
      totalColor='#7cb442'
    />
  </Layout>
)

export default HorizontalBarChartPage
